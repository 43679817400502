#about{
  p {
    text-align: justify;
  }
  
  .nav-link{
    border: 1px solid #A3DBE7;
  }

  .nav-link.active{
    background: linear-gradient(90deg, #C9A6FC 0%, #9746FF 100%);
    color: white !important;
    border: none;
  }

  #career-picture{
    width: 50%;
    max-height: 420px;
  }

  #history-picture{
    max-height: 420px;
  }

  #about-diario{
    max-height: 380px;
  }

  #about-heraldo{
    max-height: 380px;
  }

  @media screen and (min-width: 768px) {
    #career-picture{
      width: auto;
    }
    #career-secondary {
      margin-top: calc(1.8rem + 0.6vw);;
    }
    #career-title{
      white-space: nowrap;
    }
  }
}