#terms {
  .nav-link{
    border-right: 1px solid black;
    border-bottom: 0;
    border-top: 0;
    border-left: 0;
  }
  
  .nav-link.active{
    border-right: 0;
    border-bottom: 1px solid black;
    border-left: 1px solid black;
    border-top: 1px solid black;
  }

  .text-content{
    p{
      padding-top: 10px;
      padding-bottom: 10px;
    }
    h4{
      margin-top: 25px;
    }
  }
}