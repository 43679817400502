#courses{
  .accordion{
    --bs-accordion-btn-bg: #FFF;
    --bs-accordion-active-bg: #FFF;
    --bs-accordion-border-width: 0px;
  }

  .accordion-button{
    border-top-left-radius: var(--bs-border-radius-pill);
    border-top-right-radius: var(--bs-border-radius-pill);
    border-bottom-left-radius: var(--bs-border-radius-pill);
    border-bottom-right-radius: var(--bs-border-radius-pill);
    font-size: 18px;
  }

  .accordion-button:focus{
    box-shadow: none;
  }

  .plus-icon{
    min-width: 24px;
  }

  .accordion-button:not(.collapsed){
    color: #6915AB;
    .plus-icon path{
      d: path("M18 12H6");
    }
  }

  .accordion-button.collapsed{
    .plus-icon path{
      d: path("M12 6v12m6-6H6")
    }
  }
  
  .accordion-button::after{
    background-image: none;
    visibility: hidden;
  }

  #course-image{
    max-height: 300px;
  }
}