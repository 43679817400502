#checkout {
  ul {
    li {
      width: 25%;

      .nav-link {
        width: 100%;
        border: 1px solid black;
        color: black;

        &.active {
          background-color: #9746ff;
          color: white;
        }
      }
    }
  }
}
