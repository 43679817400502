#faq{
  .accordion{
    --bs-accordion-btn-bg: #EEF2F6;
    --bs-accordion-active-bg: #EEF2F6;
    --bs-accordion-border-width: 0px;
  }

  .accordion-button{
    border-top-left-radius: var(--bs-border-radius-pill);
    border-top-right-radius: var(--bs-border-radius-pill);
    border-bottom-left-radius: var(--bs-border-radius-pill);
    border-bottom-right-radius: var(--bs-border-radius-pill);
    font-weight: bolder;
    font-size: 20px;
  }

  .accordion-button:focus{
    box-shadow: none;
  }

  .accordion-button:not(.collapsed){
    color: #6915AB;
  }
  
  .accordion-button::after{
    background-image: none;
  }
}