#login,
#signin {
  min-height: calc(100vh - 372px);
  display: flex;
  justify-content: center;

  img {
    width: 550px;
    height: 570px;
  }

  h1 {
    margin-top: 90px;
  }

  .form-check {
    display: flex;
  }

  #backIcon {
    font-size: 40px;
  }

  .back-container {
    align-self: flex-end;
  }

  @media screen and (min-width: 768px) {
    .main-options {
      justify-content: unset;
      gap: 40px;
    }
  }

  @media screen and (min-width: 1200px) {
    .main-options {
      justify-content: space-between;
    }

    img {
      margin-left: 140px;
    }

    .forgot-img {
      margin-left: 140px;
    }
  }

  .form-check {
    display: flex;
  }

  #backIcon {
    font-size: 40px;
  }

  .main-options {
    justify-content: space-between;
  }

  .back-container {
    align-self: flex-end;
  }

  @media screen and (min-width: 768px) {
    .main-options {
      justify-content: unset;
      gap: 40px;
    }
  }

  @media screen and (min-width: 1200px) {
    .main-options {
      justify-content: space-between;
    }

    .login-img {
      margin-left: 140px;
    }

    .forgot-img {
      margin-left: 140px;
    }
  }
}
