#home {
  #testimonios {
    background-color: purple;
    background-image: url("../../public/banner-cuarzo.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 400px;
  }

  #hero-image {
    width: 100%;
    height: calc(0.5625 * 100vw);
  }

  #effect-cta {
    height: 50%;
    width: 100%;
    transform: translateY(20%);
    padding: 0;
  }

  #effect-redes {
    height: 50%;
    width: 100%;
    bottom: 0;
    padding: 0;
  }

  #cta-container {
    max-width: 1300px;
  }

  #socials-container {
    max-width: 1300px;
  }

  #hero-container {
    height: calc(0.5625 * 100vw - 10px);
    max-height: calc(100vh - 90px);
    overflow-y: hidden;
  }

  .hero-text {
    font-size: calc(1rem + 0.3vw);
  }

  .cta-btn {
    font-size: 0.9rem;
  }

  .course-image {
    min-height: 200px;
    max-height: 200px;
    object-fit: cover;
  }

  .highlight-card {
    min-height: 100px;
    max-height: 100px;
    object-fit: fill;
  }

  .numbers-icon {
    width: 65px;
    height: 65px;
  }

  .accordion{
    --bs-accordion-btn-bg: #FFF;
    --bs-accordion-active-bg: #FFF;
    --bs-accordion-border-width: 0px;
  }

  .accordion-button{
    border-top-left-radius: var(--bs-border-radius-pill);
    border-top-right-radius: var(--bs-border-radius-pill);
    border-bottom-left-radius: var(--bs-border-radius-pill);
    border-bottom-right-radius: var(--bs-border-radius-pill);
    font-size: 18px;
  }

  .accordion-button:focus{
    box-shadow: none;
  }

  .plus-icon{
    min-width: 24px;
  }

  .accordion-button:not(.collapsed){
    color: #6915AB;
    .plus-icon path{
      d: path("M18 12H6");
    }
  }

  .accordion-button.collapsed{
    .plus-icon path{
      d: path("M12 6v12m6-6H6")
    }
  }
  
  .accordion-button::after{
    background-image: none;
    visibility: hidden;
  }

  #course-image{
    max-height: 300px;
  }

  .bg-gray-home {
    background-color: #85aacd;
  }

  .bg-purple-gradient-home {
    background: linear-gradient(90deg, #9746ff 0%, #a58ce7 100%);
  }

  .bg-pink-gradient-home {
    background: linear-gradient(90deg, #9746ff 0%, #ffa9dd 100%);
  }

  .fb-button {
    background-color: #1cc8ff;
  }

  .ig-button {
    background-color: #ffa9dd;
  }

  .yt-button {
    background-color: #c9a6fc;
  }

  .tl-button {
    background-color: #a58ce7;
  }

  .wp-logo {
    position: fixed;
    right: 0;
    bottom: 0;
    background-color: none;
    border: 0;
    z-index: 1000;
  }

  .subscribe-section {
    width: 90%;
  }

  @media only screen and (min-width: 530px) {
    .hero-text {
      font-size: calc(1.425rem + 0.8vw);
      margin-bottom: 70px;
    }
  }

  @media only screen and (min-width: 740px) {
    .cta-btn {
      font-size: 1.2rem;
    }

    .subscribe-section {
      width: 50%;
    }
  }

  @media only screen and (min-width: 768px) {
    #effect-cta {
      height: 100%;
      width: 65%;
      transform: translateY(0);
    }

    #effect-redes {
      height: 100%;
      width: auto;
      transform: translateX(45%);
    }

    .numbers-icon {
      width: 85px;
      height: 85px;
    }
  }

  /*  @media only screen and (min-width: 992px){
    .hero-text {
      font-size: calc(1.325rem + 0.5vw);
      margin-bottom: 1.5rem;
    }
  } */

  @media only screen and (min-width: 1100px) {
    #img-redes {
      width: 100%;
      height: 110%;
      object-fit: cover;
    }

    .numbers-icon {
      width: 100px;
      height: 100px;
    }
  }
}
