footer {
  background-color: #eef2f6;
  display: flex;
  justify-content: space-evenly;
  padding: 40px 0;

  .brand-text {
    font-size: 20px;
    font-weight: bold;
    color: #9846d9;
  }
  
  #menu {
    text-align: center;
    #menu-items {
      display: flex;
      text-align: left;
      max-width: 190px;
    }
  }

  #zenith-link{
    color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  }

  svg {
    font-size: 24px;
    color: #9846d9;
  }

  a, p {
    color: var(--bs-body-color);
    text-decoration: none;
  }
}
