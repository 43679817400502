#course-page,
#challenge-page {
  .checkout-buttons {
    max-width: 280px;
  }

  .nav-item {
    flex-grow: 1;
  }

  .nav-link {
    width: 100%;
    color: black;
    border-right: 1px solid black;
    border-bottom: 1px solid black;
    border-top: 0;
    border-left: 0;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
  }

  .nav-link.active {
    color: white;
    background-color: #1cc8ff;
  }

  #course-tabs-tab-trabajos {
    border-right: 0;
  }

  #course-tabs-tab-testimonios {
    border-left: 1px solid black;
    border-right: 0;
  }

  #course-tabs-tabpane-contenido {
    border-bottom: 0;
  }

  .accordion {
    --bs-accordion-active-bg: #fff;
    --bs-accordion-border-width: 0px;
  }

  .accordion-item {
    border-bottom: 1px solid black;
  }

  .accordion-item:last-of-type {
    border-bottom: 0;
  }

  .accordion-button:focus {
    box-shadow: none;
  }

  .accordion-button:not(.collapsed) {
    border-bottom: 1px solid black;
    color: var(--bs-accordion-btn-color);
  }

  .accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-icon);
  }

  .course-image {
    min-height: 200px;
    max-height: 200px;
    object-fit: cover;
  }
}
