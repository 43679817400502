#header {
  height: 90px;
  background-color: #9846d9;
  max-width: 100%;

  #logo {
    margin-left: auto;
    margin-right: auto;
  }

  svg {
    color: white;
    font-size: 32px;
    margin-right: 12px;
    &.text-blue {
      color: #1cc8ff;
    }
  }

  @media screen and (min-width: 1100px) {
    #logo {
      padding-left: 60px;
      margin-left: 0;
      margin-right: 0;
    }
    svg {
      font-size: 36px;
    }
  }

  #offcanvas-body {
    display: flex;
    flex: 7;
    justify-content: flex-end;
    font-size: 24px;
  }

  --bs-navbar-toggler-border-color: white;
  --bs-navbar-toggler-icon-bg: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255, 255, 255, 1%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e');
  --bs-navbar-toggler-focus-width: 0;
}

.navLinks {
  color: white;
  margin: 0 10px;
  text-decoration: none !important;
  opacity: 0.75;
  font-size: 18px;
  width: auto;
  height: 100%;
  display: flex;
  &:hover {
    opacity: 1;
  }
  &.active {
    opacity: 1;
  }
}

#offcanvasNavbar-expand-lg {
  background-color: #9846d9;
  .btn-close {
    --bs-btn-close-color: white;
    --bs-btn-close-bg: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath stroke=%27rgba%28255, 255, 255, 1%29%27 d=%27M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z%27/%3e%3c/svg%3e');
    --bs-btn-close-focus-shadow: none;
    opacity: 1;
  }
}

.navbar-nav {
  a,
  div {
    padding-top: 3px;
    padding-bottom: 3px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  svg {
    color: white;
    font-size: 32px;
    margin-right: 12px;
    &.text-blue {
      color: #1cc8ff;
    }
  }

  @media screen and (min-width: 1100px) {
    svg {
      font-size: 36px;
    }
  }
}
