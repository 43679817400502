@import "./about.scss";
@import "./admin.scss";
@import "./auth.scss";
@import "./checkout.scss";
@import "./contact.scss";
@import "./course.scss";
@import "./courses.scss";
@import "./faq.scss";
@import "./footer.scss";
@import "./header.scss";
@import "./home.scss";
@import "./memberships.scss";
@import "./profile.scss";
@import "./terms.scss";

@import "~bootstrap/scss/bootstrap.scss";

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Nunito:wght@400;700&display=swap");

body {
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  min-height: 100vh;
  padding-top: 90px;
}

.wsp {
  white-space: pre-line;
}

.text-purple-l {
  color: #6915ab;
}

.text-purple-m {
  color: #9746ff;
}

.text-purple-s {
  color: #9845d9;
}

.text-purple-xs {
  color: #c2a8e3;
}

.text-blue {
  color: #1cc8ff;
}

.text-red {
  color: #ff4141;
}

.text-green {
  color: #b2ff8d;
}

.text-gray {
  color: #eef2f6;
}

.purple-gradient {
  background: linear-gradient(90deg, #6915ab 0%, #9746ff 100%);
  background-color: #6915ab;
}

.purple-blue-gradient {
  background: linear-gradient(90deg, #9746ff 0%, #6ed3ed 100%);
}

.bg-purple-l {
  background-color: #6915ab;
}

.bg-purple-m {
  background-color: #9746ff;
}

.bg-purple-s {
  background-color: #9845d9;
}

.bg-purple-xs {
  background-color: #c2a8e3;
}

.bg-blue {
  background-color: #1cc8ff;
}

.bg-red {
  background-color: #ff4141;
}

.bg-green {
  background-color: #b2ff8d;
}

.bg-gray {
  background-color: #eef2f6;
}

.border-purple-l {
  border-color: #6915ab !important;
}

.border-purple-m {
  border-color: #9746ff !important;
}

.border-purple-s {
  border-color: #9845d9 !important;
}

.border-purple-xs {
  border-color: #c2a8e3 !important;
}

.border-blue {
  border-color: #1cc8ff !important;
}

.border-red {
  border-color: #ff4141 !important;
}

.border-green {
  border-color: #006268 !important;
}

.border-gray {
  border-color: #eef2f6 !important;
}

.navLinks.active {
  opacity: 1;
  p {
    border-bottom: 2px solid white;
  }
}

.icon-blue {
  svg {
    color: #1cc8ff;
  }
}

.pointer {
  cursor: pointer;
}

.btn.bg-purple-l:hover {
  background-color: #6915ab;
  opacity: 0.8;
}

.btn.bg-purple-m:hover {
  background-color: #9746ff;
  opacity: 0.8;
}

.btn.bg-gray:hover {
  background-color: #eef2f6;
  opacity: 0.8;
}

.btn.purple-gradient:hover {
  background: linear-gradient(90deg, #6915ab 0%, #9746ff 100%);
  background-color: #6915ab;
  opacity: 0.8;
}

#backIcon {
  position: absolute;
  top: 120px;
  left: 250px;
  cursor: pointer;
  font-size: 40px;
}

#admin-header {
  height: 90px;
  background-color: #9846d9;
  max-width: 100%;
  display: flex;
  .leenith-icon {
    height: 48px;
    width: 48px;
  }
}

#cover {
  background-color: purple;
  min-height: 250px;
  #cover-image {
    object-fit: cover;
  }
}

.w-33 {
  width: 33%;
}

.mh-100 {
  min-height: 100px;
}

.min-vh-50 {
  height: auto;
  min-height: 50vh;
}

.loader {
  width: 100%;
  min-height: calc(100vh - 362px);
  .spinner-border {
    color: #9746ff;
    --bs-spinner-width: 3rem;
    --bs-spinner-height: 3rem;
  }
}

#custom-toast {
  width: 50%;
  min-width: 500px;
  min-height: 150px;
  position: absolute;
  top: 10%;
  left: 2%;
}

.fs-small {
  font-size: 14px;
}

.fs-normal {
  font-size: 16px;
}

.player-wrapper {
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */

  .react-player {
    position: absolute;
    top: 0;
    left: 0;

    iframe {
      border-radius: 16px;
      width: 100%;
      height: 100%;
    }
  }
}

$placeholderColor: #b2b5b8;

/* do not group these rules */
.placeholder-low-opacity::-webkit-input-placeholder {
  color: $placeholderColor;
}
.placeholder-low-opacity:-moz-placeholder {
  /* FF 4-18 */
  color: $placeholderColor;
  opacity: 1;
}
.placeholder-low-opacity::-moz-placeholder {
  /* FF 19+ */
  color: $placeholderColor;
  opacity: 1;
}
.placeholder-low-opacity:-ms-input-placeholder {
  /* IE 10+ */
  color: $placeholderColor;
}
.placeholder-low-opacity::-ms-input-placeholder {
  /* Microsoft Edge */
  color: $placeholderColor;
}
.placeholder-low-opacity::placeholder {
  /* modern browser */
  color: $placeholderColor;
}

.course-image{
  min-height: 277px;
  max-height: 277px;
  object-fit: cover;
}

.card-body{
  background-color: #EEF2F6;
}

.card-footer{
  background-color: #EEF2F6;
}

@media only screen and (min-width: 1025px) {
  .memberships-previews {
    display: flex;
  }
}