#memberships{
  .nav-link{
    min-width: 256px;
    width: 256px;
    height: 144px;
    border: 0;

    img{
      object-fit: cover;
    }
  }

  .normal{
    background-color: #D9D9D933;
  }

  .featured{
    background-color: #9746FF99;
  }

  .list-group-item {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 1px solid black;
  }
}