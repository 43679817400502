#admin {
  #admin-navbar {
    .max-h-90 {
      max-height: 90px;
    }
    a {
      display: block;
      font-weight: bold;
      color: white;
      padding: 8px 0 8px 40px;
      text-decoration: none;
      border-bottom: 1px solid green;

      &:hover {
        background-color: #9846d9;
      }
    }
    .actived {
      background-color: #9846d9;
    }
  }
  #log-out-btn {
    position: fixed;
    bottom: 20px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
}

#courseForm {
  ul {
    li {
      .nav-link {
        width: 100%;
        border: 1px solid black;
        color: black;

        &.active {
          background-color: #9746ff;
          color: white;
        }
      }
    }
  }
}
